import { createUmi } from '@metaplex-foundation/umi-bundle-defaults'
import { walletAdapterIdentity } from '@metaplex-foundation/umi-signer-wallet-adapters'
import { mplTokenMetadata } from '@metaplex-foundation/mpl-token-metadata'
import { useWallet } from '@solana/wallet-adapter-react'

const useUmi = () => {
  // Import useWallet hook
  const wallet = useWallet()

  // Create Umi instance
  const umi = createUmi('https://mainnet.helius-rpc.com/?api-key=39319b3a-4a57-42df-87a6-bd4beb2356ff')
    .use(mplTokenMetadata())
    // Register Wallet Adapter to Umi
    .use(walletAdapterIdentity(wallet))

  return umi
}

export default useUmi