import React, { useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { Button, CircularProgress } from '@mui/material';
import { percentAmount, generateSigner } from '@metaplex-foundation/umi';
import { createProgrammableNft, fetchAllDigitalAssetWithTokenByOwner, TokenStandard, updateV1, transferV1 } from '@metaplex-foundation/mpl-token-metadata';
import { transactionBuilder, publicKey } from '@metaplex-foundation/umi';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'; // Import the WalletMultiButton
import useUmi from './useUmi';
import StatusMessage from './StatusMessage';

const retryOperation = (operation, delay, times) => new Promise((resolve, reject) => {
    const attempt = (remainingAttempts) => {
        operation()
            .then(resolve)
            .catch((error) => {
                if (remainingAttempts === 0) {
                    reject(error);
                } else {
                    setTimeout(() => attempt(remainingAttempts - 1), delay);
                }
            });
    };
    attempt(times);
});

function Migrate({ network }) {
    const umi = useUmi();
    const wallet = useWallet();

    const [nftList, setNftList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedNft, setSelectedNft] = useState(null);
    const [jsonMetadata, setJsonMetadata] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [statusMessage, setStatusMessage] = useState({ message: '', type: '' });
    const [migrationSuccess, setMigrationSuccess] = useState(false);

    useEffect(() => {
        if (wallet.connected) {
            fetchNFTs();
        }
    }, [wallet.connected]);

    const TARGET_UPDATE_AUTHORITY = "EZpCW9PJJ4W7CgRph6hDHd9pRhH8ihgeRNpqHPzaQybk";

    const fetchNFTs = async () => {
        setLoading(true);
        // Don't clear the status message here
        try {
            const nfts = await fetchAllDigitalAssetWithTokenByOwner(umi, wallet.publicKey);

            const filteredByAuthority = nfts.filter(nft =>
                nft.metadata.updateAuthority &&
                nft.metadata.updateAuthority === TARGET_UPDATE_AUTHORITY
            );

            const filteredNftsWithUriData = await Promise.all(filteredByAuthority.map(async (nft) => {
                const metadataUri = nft.metadata.uri;
                const response = await fetch(metadataUri);
                const metadata = await response.json();

                const hasDesiredTraits = metadata.attributes && metadata.attributes.some(attribute => {
                    const desiredTraits = ['Legendary', 'Mythic', 'Special', '1/1'];
                    return attribute.trait_type === 'Rarity' && desiredTraits.includes(attribute.value);
                });

                if (hasDesiredTraits) {
                    return {
                        ...nft,
                        image: metadata.image,
                        mint: nft.metadata.mint,
                        uri: metadataUri,
                    };
                }
                return null;
            }));

            const filteredNfts = filteredNftsWithUriData.filter(nft => nft !== null);

            setNftList(filteredNfts);
        } catch (error) {
            console.error('Error fetching NFTs:', error);
            setStatusMessage({ message: 'Error fetching NFTs. Please try again later.', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleNftSelection = async (nft) => {
        setSelectedNft(nft);
        setIsEditing(true);
        setLoading(true);
        setMigrationSuccess(false);
        // Don't clear the status message here
        try {
            const metadataResponse = await fetch(nft.metadata.uri);
            const metadata = await metadataResponse.json();
            setSelectedNft(prevState => ({
                ...prevState,
                name: metadata.name,
                image: metadata.image,
                mintAddress: nft.publicKey,
                uri: metadataResponse.url
            }));
            setJsonMetadata(JSON.stringify(metadata, null, 2));
        } catch (error) {
            console.error('Error fetching NFT metadata:', error);
            setStatusMessage({ message: 'Error fetching NFT metadata. Please try again later.', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const cloneAndTransferNFT = async () => {
        if (!selectedNft) {
            setStatusMessage({ message: 'No NFT selected for cloning.', type: 'error' });
            return;
        }

        setLoading(true);
        setStatusMessage({ message: 'Migrating...', type: 'loading' });

        const staticCreatorAddress = new PublicKey('4vLgHARr1LLLhkwUWAM4kRZZs5jX4TTwMRZnBLRz8zmK');

        try {
            const metadata = JSON.parse(jsonMetadata);
            const mint = generateSigner(umi);
            const newOwnerAddress = publicKey('9KPwbjAc9iFqCDtzqr4zr1UncLMbEdFvi6cJJLY9tWC6');
            const oldMint = selectedNft.mintAddress;

            let builder = transactionBuilder()
                .add(createProgrammableNft(umi, {
                    mint,
                    name: metadata.name,
                    uri: selectedNft.uri,
                    symbol: metadata.symbol,
                    description: metadata.description,
                    creators: [{
                        address: staticCreatorAddress,
                        share: 100,
                    }],
                    sellerFeeBasisPoints: percentAmount(5.5),
                }))
                .add(updateV1(umi, {
                    mint: mint,
                    authority: umi.identity,
                    tokenOwner: umi.identity.publicKey,
                    newUpdateAuthority: newOwnerAddress,
                }))
                .add(transferV1(umi, {
                    mint: oldMint,
                    authority: umi.identity,
                    tokenOwner: umi.identity.publicKey,
                    destinationOwner: newOwnerAddress,
                    tokenStandard: TokenStandard.NonFungible,
                }));
            const confirmResult = await builder.sendAndConfirm(umi);
            console.log(confirmResult);

            // Set the success message before resetting loading state
            setStatusMessage({ message: 'Success!', type: 'success' });
            setMigrationSuccess(true);
            setLoading(false);

            // Fetch NFTs after setting success message
            await fetchNFTs();
        } catch (error) {
            console.error('Error cloning or transferring NFT:', error);
            setStatusMessage({ message: 'Error! Try again.', type: 'error' });
            setLoading(false);
        }
    };

    // Debugging logs to track state changes
    useEffect(() => {
        console.log('statusMessage:', statusMessage);
        console.log('loading:', loading);
        console.log('migrationSuccess:', migrationSuccess);
    }, [statusMessage, loading, migrationSuccess]);

    return (
        <div>
            {/* Navigation Menu */}
            <div className="nav-header">
                <div className="nav-content">
                    <a href="/" className="logo"><img src="/buzz.png" alt="Logo" /></a>
                    <div className="spacer"></div>
                    <div className="links">
                        <a href="https://builder.nctr.buzz" target="_blank" rel="noopener noreferrer">NFT Builder</a>
                        <a href="https://teleport.nctr.buzz" target="_blank" rel="noopener noreferrer">Teleport</a>
                        <a href="https://nctr.buzz" target="_blank" rel="noopener noreferrer">NCTR Buzz</a>
                    </div>
                    <div className="spacer"></div>
                    <div className="links">
                        <a href="https://twitter.com/nctr_solana" target="_blank" rel="noopener noreferrer"><img src="/icon-twitter.svg" alt="Twitter" /></a>
                        <a href="https://dexscreener.com/solana/gbjpjemczthkf4yqzzqurzr9z6sikamqfp8b2v9zneiv" target="_blank" rel="noopener noreferrer">DexScreener</a>
                        <a href="https://jup.ag/swap/SOL-NCTR" target="_blank" rel="noopener noreferrer">Buy NCTR</a>
                    </div>
                    <div className="spacer"></div>
                    <div>
                        <WalletMultiButton />
                    </div>
                </div>
            </div>
    
            {/* Main Content with Header and Box */}
            <div className="main-content">
                <h2 className="main-header">Lazy Migration</h2>
        
                <div className="box" style={{ padding: '20px' }}>
                    {/* Reserve space for status message to prevent layout shifts */}
                    <div style={{ minHeight: '40px', marginBottom: '10px' }}>
                        <StatusMessage message={statusMessage.message} type={statusMessage.type} />
                    </div>
                    {!wallet.connected ? (
                        <div className="connectBox">
                            <h2>WALLET IS NOT CONNECTED</h2>
                        </div>
                    ) : !isEditing ? (
                        <>
                            <Button onClick={fetchNFTs} variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} className="custom-spinner-color" /> : 'Refresh NFTs'}
                            </Button>
                            <div className="nft-grid">
                                {loading ? <CircularProgress size={24} className="custom-spinner-color2" /> : nftList.map((nft, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleNftSelection(nft)}
                                        className={`nft-item ${selectedNft === nft ? 'selected' : ''}`}
                                    >
                                        <img src={nft.image || '/fallbackImage.jpg'} alt={nft.name} style={{ width: '100%', height: 'auto' }} />
                                    </div>
                                ))}
                            </div>
                            {/* Add Guide Text Below the NFTs */}
                            <span className="guide-text">Select an NFT you want to migrate</span>
                        </>
                    ) : (
                        <div className="nft-details">
                            <img src={selectedNft.image || '/fallbackImage.jpg'} alt={selectedNft.name} style={{ width: '100%', height: 'auto', maxWidth: '300px' }} />
                            <h1>{selectedNft.name}</h1>
                            <h2>
                                <a href={`https://solscan.io/token/${selectedNft.mintAddress}`} target="_blank" rel="noopener noreferrer" className="customLink">
                                    {selectedNft.mintAddress}
                                </a>
                            </h2>
                            {!migrationSuccess && (
                                <Button onClick={cloneAndTransferNFT} variant="contained" color="primary" disabled={loading}>
                                    {loading ? <CircularProgress size={24} className="custom-spinner-color" /> : 'Migrate NFT'}
                                </Button>
                            )}
                            <Button
                                onClick={() => {
                                    if (migrationSuccess) {
                                        setIsEditing(false);
                                        setSelectedNft(null);
                                        setJsonMetadata('');
                                        setStatusMessage({ message: '', type: '' }); // Clear status message
                                        setMigrationSuccess(false); // Reset success state for next operation
                                    } else {
                                        setIsEditing(false);
                                    }
                                }}
                                variant="outlined"
                                style={{ marginLeft: '10px' }}
                            >
                                {migrationSuccess ? 'Migrate More NFTs' : 'Cancel'}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
export default Migrate;