import React from 'react';
import PropTypes from 'prop-types';

const StatusMessage = ({ message, type }) => {
    const getColor = () => {
        switch (type) {
            case 'success':
                return 'green';
            case 'error':
                return 'red';
            case 'loading':
                return '#ffc700';
            default:
                return 'black';
        }
    };

    return (
        <div style={{ color: getColor(), padding: '10px', marginBottom: '10px' }}>
            {message}
        </div>
    );
};

StatusMessage.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default StatusMessage;